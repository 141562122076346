<template>
  <Modal
      v-model="isOpen"
      :fullscreen="isFullscreen"
      :modal-transition="modalTransition"
      :click-out="isClickOut"
      :disable-motion="isMotionDisabled"
      :max-width="!isFullscreen ? maxWidth : undefined"
      :remove-backdrop="isBackdropRemoved"
      :modal-class="channelModal ? modalClass : undefined"
  >
    <div class="card">
      <button class="close" @click="$emit('cancelDialog')"></button>
      <slot name="body"></slot>
      <div class="btn_group center" v-if="btnGroup">
        <button class="btn basic w_l h_m" @click="$emit('cancelDialog')">{{cancel}}</button>
        <button class="btn strong w_l h_m" @click="$emit('yesDialog')" :disabled="disabled">{{yes}}</button>
      </div>

    </div>
  </Modal>
</template>

<script>
import { Modal } from 'vue-neat-modal';

export default {
  name: "Dialog",
  components: {
    Modal,
  },
  props: {
    isOpen: false,
    yes: {
      type: String,
      default: 'Yes',
      required: false
    },
    cancel: {
      type: String,
      default: 'Cancel',
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    btnGroup: {
      type: Boolean,
      default: true,
    },
    channelModal: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      isFullscreen: false,
      modalTransition: "scale",
      isClickOut: true,
      isMotionDisabled: false,
      isBackdropRemoved: false,
      maxWidth: "500px",
      modalClass: 'channel',
    }
  }
}
</script>

<style scoped>

.vue-neat-modal--fullscreen .card {
  min-height: 100%;
  border-radius: 0;
}

.card {
  background-color: #fff;
  border-radius: 4px;
  padding: 24px;
}

.card h1 {
  margin-top: 0;
}

.btn_group.center {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
</style>